<template>
  <!-- 积分商城 -->
  <div class="nav">
    <!-- 分类与商品 -->
    <div class="shoping">
      <div class="lefthead">
        <input
          type="text"
          v-model="keyword"
          onkeyup="this.value=this.value.replace(/[^\u4e00-\u9fa5]/g,'')"
          placeholder="请输入名字"
        />
        <i class="el-icon-search" @click="search(keyword)"></i>
      </div>
      <div class="shopleft d-flex">
        <div
          v-for="(item, index) in classification"
          :key="index"
          class="listbox color-white f-13"
        >
          <span
            @click="sort(item.id, index, item.name)"
            :class="name == index ? 'gold' : ''"
            >{{ item.name }}</span
          >
        </div>
        <div class="left">
          <div class="ll">
            <div>{{ buttonText }}</div>
            <img
              src="@/assets/images/shopping/shaixuan.png"
              alt
              @click="ctan"
            />
            <div class="lmn" v-show="show == true">
              <div
                class="lmni"
                v-for="(item, index) in classification"
                :key="index + 'zx'"
              >
                <span @click="sort(item.id, index, item.name)">{{
                  item.name
                }}</span>
              </div>
            </div>
          </div>
          <div class="lr">
            <div class="lrl">价格排序</div>
            <div class="lrr">
              <img
                src="@/assets/images/shopping/lg.png"
                alt
                @click="shengxu"
                v-show="isshow == 2"
              />
              <img
                src="@/assets/images/shopping/xdg.png"
                alt
                @click="jiangxu"
                v-show="isshow == 1"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="shopright d-flex">
        <div v-for="(item, index) in gunlist" :key="index" class="gunlist f-13">
          <div class="price">
            <img
              style="vertical-align: middle"
              src="@/assets/images/pk/zs.png"
              alt
            />
            {{ item.price }}
          </div>
          <div class="gunnav">
            <div style="min-height: 75px">
              <img :src="item.oimg" alt width="75px" />
            </div>
            <p class="color-white">{{ item.oname }}</p>
          </div>
          <div @click="shipin(item.id)" class="duihuan color-white f-13">
            兑换
          </div>
        </div>
      </div>
    </div>

    <!-- 翻页 -->
    <div class="Turn-page">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="pageNum"
        :page-size="pageSize"
        :total="total"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getShop } from "../../api/index";
import { getShopSort } from "../../api/index";
import { shopgunAPI } from "../../api/index";

export default {
  data() {
    return {
      gunlist: [],
      classification: [],
      dialogVisible: false,
      shipinId: "",
      pageSize: 5,
      pageNum: 1,
      total: 0,
      name: Number,
      buttonText: "",
      show: false,
      isshow: 1,
      keyword: "",
      soltid: 0,
    };
  },

  mounted() {
    if (window.innerWidth < 550) {
      this.pageSize = 6;
    }
    getShop(this.pageSize, this.pageNum, "", "").then((res) => {
      // console.log(res);
      this.gunlist = res.data.rows;
      this.total = res.data.total;
    });
    getShopSort().then((req) => {
      this.buttonText = req.data.data[0].name;
      this.classification = req.data.data;
    });
  },
  methods: {
    //分类
    sort(id, index, name) {
      this.buttonText = name;
      this.show = false;
      this.name = index;
      this.classId = id;
      this.soltid = id;
      getShop(this.pageSize, this.pageNum, this.classId, "").then((res) => {
        this.gunlist = res.data.rows;
        this.total = res.data.total;
        if (this.total == 0) {
          this.$message.error("暂无该类型饰品。");
        }
      });
    },
    //el-分页
    handleCurrentChange(val) {
      this.pageNum = val;
      getShop(this.pageSize, this.pageNum, this.soltid, "").then((res) => {
        this.gunlist = res.data.rows;
        this.total = res.data.total;
      });
    },
    shipin(id) {
      shopgunAPI(id).then((res) => {
        if (res.data.data.error == "余额不足") {
          this.$message.error("余额不足");
        } else if (res.data.code == 200) {
          this.$message.success("兑换成功");
        } else {
          // console.log(res);
          this.$message.error("兑换失败");
        }
      });
    },
    ctan() {
      this.show = true;
    },
    //降序
    jiangxu() {
      function compare(property) {
        return function (obj1, obj2) {
          var value1 = obj1[property];
          var value2 = obj2[property];
          return value2 - value1; // 降序
        };
      }
      this.gunlist.sort(compare("price"));
      this.isshow = 2;
    },
    //升序
    shengxu() {
      this.isshow = 1;
      function compare(property) {
        return function (obj1, obj2) {
          var value1 = obj1[property];
          var value2 = obj2[property];
          return value1 - value2; // 降序
        };
      }
      this.gunlist.sort(compare("price"));
    },
    //搜索
    search(key) {
      //oName
      getShop(this.pageSize, this.pageNum, "", key).then((res) => {
        if (res.data.total == 0) {
          this.$message({
            message: "查无此饰品",
            type: "warning",
          });
        } else if (res.data.total != 0) {
          this.gunlist = res.data.rows;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.gold {
  color: #fff000;
}

.nav {
  width: 1131px;
  height: 460px;
  margin: 50px auto;
  background: url("~@/assets/images/home/mainallbg.png") no-repeat;
  background-size: 100% 100%;

  .shoping {
    margin-top: 25px;

    .lefthead {
      display: none;
    }

    .shopleft {
      width: 600px;
      height: 30px;
      margin: auto;
      justify-content: space-around;
      padding-top: 25px;

      .listbox {
        display: block;
        font-size: 18px;
        font-weight: none;
        text-align: center;
        line-height: 20px;
      }

      .left {
        display: none;
      }
    }

    .shopright {
      width: 90%;
      justify-content: space-around;
      margin: 55px auto;
      overflow: hidden;

      .gunlist {
        width: 154px;
        height: 226px;
        background-image: url(~@/assets/images/shopping/gunbox.png);
        background-size: 100% 100%;
        margin: 5px 5px;
        color: #fff000;
        padding: 35px 18px;
        box-sizing: border-box;

        .price {
          text-align: right;
        }

        p {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          // 没置超出部分显示省略号
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .gunnav {
        text-align: center;
        min-height: 112px;
      }

      .duihuan {
        border: none;
        width: 64px;
        height: 25px;
        background-image: url(~@/assets/images/shopping/buybtn.png);
        background-size: 100% 100%;
        text-align: center;
        margin: 5px 8px;
        border-radius: 5px;
        line-height: 25px;

        @media (min-width: 550px) {
          margin: 3px auto;
          width: 95px;
          height: 26px;
          font-size: 15px;
        }
      }
    }
  }

  .Turn-page {
    margin: 30px auto;

    .el-pagination {
      display: flex;
      justify-content: center;
    }
  }
}

@media (max-width: 550px) {
  .nav {
    width: 100%;
    height: 680px;
    margin: 30px auto 0;
    background: none;

    .shoping {
      height: 100%;
      margin-top: -30px;
      overflow: auto;

      .lefthead {
        width: 200px;
        height: 30px;
        margin-top: 10px;
        margin-left: 180px;
        display: flex;
        align-items: center;
        justify-content: center;

        input {
          width: 100px;
          padding-left: 10px;
          border: none;
          box-shadow: none;
          outline: none;
          border-radius: 10px;
          background-color: RGBA(32, 55, 95, 0.1);
          color: #fff;
        }

        .el-icon-search {
          color: #fff;
          font-size: 18px;
        }
      }

      .shopleft {
        width: 100%;
        height: 40px;
        margin: auto;
        justify-content: space-around;
        padding-top: 0px;

        .listbox {
          display: none;
          font-size: 18px;
          font-weight: none;
          text-align: center;
          line-height: 20px;
        }

        .left {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 100%;

          .ll {
            flex: none;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            color: #fff;
            width: 120px;
            height: 100%;
            margin-left: 10px;
            background-image: url("../../assets/images/shopping/bg10086.png");
            background-size: 100% 100%;
            position: relative;

            img {
              width: 20px;
              height: 20px;
            }

            .lmn {
              position: absolute;
              top: 40px;
              left: 0px;
              width: 100px;
              background-image: url("../../assets/images/shopping/bg123.png");
              background-size: 100% 100%;

              .lmni {
                width: 100px;
                height: 40px;
                text-align: center;
                line-height: 40px;
              }
            }
          }

          .lr {
            flex: none;
            color: #fff;
            width: 120px;
            height: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .lr {
              width: 50px;
              height: 100%;
            }
          }
        }
      }

      .shopright {
        width: 90%;
        justify-content: space-around;
        margin: 5px auto;
        overflow: hidden;
        flex-wrap: wrap;

        .gunlist {
          width: 46%;
          height: 226px;
          background-image: url(~@/assets/images/shopping/gunbox.png);
          background-size: 100% 100%;
          margin: 5px 5px;
          color: #fff000;
          padding: 35px 18px;
          box-sizing: border-box;

          .price {
            text-align: right;
          }

          p {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            // 没置超出部分显示省略号
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .gunnav {
          text-align: center;
          min-height: 112px;
        }

        .duihuan {
          border: none;
          width: 64px;
          height: 25px;
          background-image: url(~@/assets/images/shopping/buybtn.png);
          background-size: 100% 100%;
          text-align: center;
          margin: 5px 8px;
          border-radius: 5px;
          line-height: 25px;

          @media (min-width: 550px) {
            margin: 3px auto;
            width: 95px;
            height: 26px;
            font-size: 15px;
          }
        }
      }
    }

    .Turn-page {
      margin: 53px auto;

      .el-pagination {
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
